import { getStyle } from 'element-plus/lib/utils/dom/style'
import { createApp } from 'vue'
import { delay } from '@shared/util/delay'
import { getTextWidth } from '../../../common/src/util/dom'
import OverTip from './OverTip.vue'

let tooltip
let popperClass
let placement
// eslint-disable-next-line complexity
const handleMouseEnter = (e, type, binding) => {
  switch (type) {
    case 'overTip':
      popperClass = 'singleton-tooltip'
      placement = 'top'
      break

    case 'tableOverTip':
      popperClass = 'qs-plus-table-tooltip'
      placement = 'top'
      break

    case 'overLeftTip':
      popperClass = 'singleton-tooltip'
      placement = 'left'
      break

    case 'overRightTip':
      popperClass = 'singleton-tooltip'
      placement = 'right'
      break
  }
  if (binding) {
    popperClass = `${popperClass} ${binding}`
  }

  if (!tooltip) {
    const tooltipEl = document.createElement('div')
    document.body.appendChild(tooltipEl)
    tooltip = createApp(OverTip).mount(tooltipEl)
  }
  const el = e.currentTarget
  const domWidth = getTextWidth(el)
  if (
    Math.round(domWidth) > el.offsetWidth
    || el.scrollWidth > el.offsetWidth
    || (el.scrollHeight > el.offsetHeight && getStyle(el, 'display') === '-webkit-box')
  ) {
    tooltip.setTooltip({
      content: el.dataset.title || el.innerText || el.textContent,
      popperClass,
      placement,
    })
    tooltip.mouseLeave = false
    delay(200).then(() => {
      !tooltip.mouseLeave && tooltip.setTooltip({
        refer: el,
        visible: true,
        content: el.dataset.title || el.innerText || el.textContent,
        popperClass,
        placement,
      })
    })
  }
}
const handleMouseLeave = () => {
  if (tooltip) {
    tooltip.mouseLeave = true
    tooltip.setTooltip({ visible: false })
  }
}

export default (Vue) => {
  Vue.directive('overTip', {
    mounted(el, binding) {
      el.addEventListener('mouseenter', (e) => {
        handleMouseEnter(e, 'overTip', binding.value)
      })

      el.addEventListener('mouseleave', handleMouseLeave)
    },
    unmounted(el) {
      el.removeEventListener('mouseenter', handleMouseEnter)
      el.removeEventListener('mouseleave', handleMouseLeave)
    },
  })

  Vue.directive('tableOverTip', {
    mounted(el) {
      el.addEventListener('mouseenter', (e) => {
        handleMouseEnter(e, 'tableOverTip')
      })

      el.addEventListener('mouseleave', handleMouseLeave)
    },
    unmounted(el) {
      el.removeEventListener('mouseenter', handleMouseEnter)
      el.removeEventListener('mouseleave', handleMouseLeave)
    },
  })

  Vue.directive('overLeftTip', {
    mounted(el) {
      el.addEventListener('mouseenter', (e) => {
        handleMouseEnter(e, 'overLeftTip')
      })

      el.addEventListener('mouseleave', handleMouseLeave)
    },
    unmounted(el) {
      el.removeEventListener('mouseenter', handleMouseEnter)
      el.removeEventListener('mouseleave', handleMouseLeave)
    },
  })

  Vue.directive('overRightTip', {
    mounted(el) {
      el.addEventListener('mouseenter', (e) => {
        handleMouseEnter(e, 'overRightTip')
      })

      el.addEventListener('mouseleave', handleMouseLeave)
    },
    unmounted(el) {
      el.removeEventListener('mouseenter', handleMouseEnter)
      el.removeEventListener('mouseleave', handleMouseLeave)
    },
  })
}
