<template>
  <el-tooltip
    v-model:visible="visible"
    :popper-options="{
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            adaptive: false,
            enabled: false,
          },
        },
      ],
    }"
    :virtual-ref="refer"
    virtual-triggering
    :placement="placement"
    :popper-class="`qs-plus-over-tip ${popperClass}`"
    :show-arrow="false"
  >
    <template #content>
      <div :style="{ width: contentWidth }">
        {{ content }}
      </div>
    </template>
  </el-tooltip>
</template>

<script>
import { getStyle } from '@shared/util/dom'
import { nextTick } from 'vue'

export default {
  data() {
    return {
      refer: null,
      visible: false,
      content: '',
      popperClass: 'singleton-tooltip',
      placement: 'top',
      contentWidth: 'auto',
    }
  },
  methods: {
    setTooltip({ refer, visible, content, popperClass, placement }) {
      this.refer = refer
      this.content = content || ''
      this.popperClass = popperClass
      this.placement = placement
      if (!visible) {
        this.visible = !!visible
      } else {
        this.contentWidth = this.refer ? getStyle(this.refer, 'width') : 'auto'
        nextTick(() => (this.visible = !!visible))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@shared/style/popper';

.el-popper.is-dark.qs-plus-over-tip,
.el-popper.is-dark.qs-plus-over-tip.singleton-tooltip {
  max-width: unset;
}
.singleton-tooltip {
  transition: transform 0.3s var(--el-transition-function-fast-bezier);
}

.qs-plus-table-tooltip {
  top: 6px !important;
}
</style>
